/* This is Desktop CSS Classes */
@media only screen and (min-width: 769px) {}

/* This is Tablet and lower CSS Classes */
/* @media only screen and (max-width: 768px) {} */

/* This is mobile and lower CSS Classes */
@media only screen and (max-width: 425px) {}

.profile-icon{
    width: 150px;
    margin-top:30px;
    margin-bottom: 20px;
}

.team-page{
    margin-top: 40px;
    margin-bottom: 40px;
}

h1 {
    font-size: 4em !important
}

@keyframes appear{
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.header {
    text-align: center;
    padding-bottom: 1.5em;
    animation: appear 1.5s ease-in-out;
}

.custom-card {
    background-color: rgb(0, 184, 135) !important;

    img {
        width: 250px;
        margin: 0px auto;
    }
}

.team-cards{
    margin-left: 50px;
    margin-right: 50px;
    border-color: none;
}