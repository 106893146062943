/* This is Desktop CSS Classes */
@media only screen and (min-width: 769px) {
    .promo-cards {
        margin-left: 200px;
        margin-right: 200px;
    }
    
    .carousel-cards {
        margin-left: 200px;
        margin-right: 200px;
    }
    
    .results-cards {
        width: 45vw;
    }
    
    .result-image{
        width: 200px;
        margin-right: 10px;
        border-radius: 2.5%;
    }
    
    .result-image-icon{
        width: 60px;
        float: left;
        margin-right: 10px;
        border-radius: 50%;
    }
    
    .results-page{
        margin-bottom: 40px;
    }

    .card-container-results{
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

/* This is Tablet and lower CSS Classes */
@media only screen and (max-width: 768px) {
    .promo-cards {
        margin-left: 100px;
        margin-right: 100px;
    }
    
    .carousel-cards {
        margin-left: 100px;
        margin-right: 100px;
    }
    
    .results-cards {
        width: 85vw;
        margin: 0px auto;
    }
    
    .result-image{
        width: 150px;
        margin-right: 10px;
        border-radius: 2.5%;
    }
    
    .result-image-icon{
        width: 50px;
        float: left;
        margin-right: 10px;
        border-radius: 50%;
    }
    
    .results-page{
        margin-bottom: 40px;
    }
}

/* This is mobile and lower CSS Classes */
@media only screen and (max-width: 425px) {
    .promo-cards {
        margin-left: 30px;
        margin-right: 30px;
    }
    
    .carousel-cards {
        margin-left: 30px;
        margin-right: 30px;
    }
    
    .result-image{
        width: 100px;
        margin-right: 10px;
        border-radius: 2.5%;
    }

    .results-cards {
        width: 85vw;
        margin: 0px auto;
    }

    .result-container{
        /* display: flex; */
        float: none;
    }

    .result-text {
        display: inline-block;
    }
    
    .result-image-icon{
        width: 40px;
        float: left;
        margin-right: 10px;
        border-radius: 50%;
    }
    
    .results-page{
        margin-bottom: 20px;
    }

    .card-container-results{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.result-text{
    text-align: right;
}

.filter-container {
    text-align: left;
}

.result-container{
    /* display: flex; */
    float: left;
}

.results-cards .card:hover {
    cursor: pointer;
}
