/* This is Desktop CSS Classes */
@media only screen and (min-width: 769px) {}

/* This is Tablet and lower CSS Classes */
@media only screen and (max-width: 768px) {}

/* This is mobile and lower CSS Classes */
@media only screen and (max-width: 425px) {

    .footer-buttons {
        font-size: 12px !important;
    }
}

.footer-icon{
    margin-left: 5px;
    margin-right: 5px;
}

.footer {
    background-color: #00b786;
}

.footer-row {
    padding-top: 20px;
    color: white;
}

.footer-brand {
    font-size: 24px;
    font-weight: 600;
}

.footer-copyright {
    font-size: 14px;
}

.footer-links a {
    color: white;
    text-decoration: none;
}

.footer-buttons {
    margin: 5px;
}