/* This is Desktop CSS Classes */
@media only screen and (min-width: 769px) {
    .login-form{
        width: 25%;
    }
}

/* This is Tablet and lower CSS Classes */
@media only screen and (max-width: 768px) {}

/* This is mobile and lower CSS Classes */
@media only screen and (max-width: 425px) {
    .login-container{
        height: 50vh;
    }
    .login-form{
        width: 50%;
    }
}

.login-container{
    height: 77.8vh;
    display: flex
}

.login-form{
    position: relative;
    margin: auto;
}

.login-button{
    background-color: #00b786 !important;
    border: #00b786 !important;
    width: 100%;
    font-weight: 600 !important;
}