/* This is Desktop CSS Classes */
/* @media only screen and (min-width: 769px) {} */

/* This is Tablet and lower CSS Classes */
/* @media only screen and (max-width: 768px) {} */

/* This is mobile and lower CSS Classes */
/* @media only screen and (max-width: 425px) {} */

.vendor-portal{
    width: 95%;
}

.vendor-view{
    padding: 20px;
}

.info-cards{
    text-align: center;
}

.banner{
    height: 25vh;
}

.info{
    margin: 40px 0px 60px 40px;
    text-align: left;

    button {
        background-color: #00b786 !important;
        border-color: #00b786 !important;
    }
}

.nav-links{
    max-width: 25% !important;
    padding: 30px;
    text-align: left;
    background-color: #00b786;
    color: white;
    font-size: 25px;

    svg {
        margin-right: 13px;
    }

    ul {
        list-style-type: none;
    }

    button {
        background: none;
        border: none;
        color: white;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    button:hover {
        background:rgba(255, 255, 255, 0.5);
    }

    .active {
        background:rgba(255, 255, 255, 0.3);
    }
}