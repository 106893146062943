/* This is Desktop CSS Classes */
@media only screen and (min-width: 769px) {
    .hero-banner{
        background-image: url("../Assets/hero-image.png");
    }

    .desktop-text {
        display: inline-block;
    }

    .mobile-text {
        display: none;
    }

    .search-button {
        font-size: 22px !important;
    }

    .search-box {
        width: 50%;
    }

    .cards {
        margin-left: 100px;
        margin-right: 100px;
    }

    .card-image {
        height: 300px !important;
        margin: 0px auto;
    }

    .carousel-info {
        margin-top: 12em;
        margin-left: 12em;
    }

    .carousel-image {
        width: 500px;
        height: 333px;
    }
}

/* This is Tablet and lower CSS Classes */
@media only screen and (max-width: 768px) {

    .hero-banner{
        background-image: url("../Assets/hero-image-mobile.png");
    }

    .search-box {
        width: 80%;
    }

    .search-button {
        font-size: 22px !important;
    }

    .desktop-text {
        display: none;
    }

    .mobile-text {
        display: inline-block;
    }

    .cards {
        margin-left: 40px;
        margin-right: 40px;
    }

    .card-image {
        height: 150px !important;
        margin: 0px auto;
    }

    .carousel-info {
        padding-top: 8em;
        padding-left: 5em;
    }

    .carousel-image {
        width: 300px;
        height: 200px;
    }

    .carousel-heading {
        font-size: 24px !important;
    }

    .carousel-info {
        font-size: 16px !important;
    }
}

/* This is mobile and lower CSS Classes */
@media only screen and (max-width: 425px) {

    .hero-banner{
        background-image: url("../Assets/hero-image-mobile.png");
    }

    .carousel-info {
        padding-top: 10em;
        padding-left: 5em;
    }

    .carousel-heading {
        font-size: 18px !important;
    }

    .carousel-info {
        font-size: 11px !important;
    }

    .carousel-image {
        width: 200px;
        height: 133px;
    }

}

.carousel-slide {
    display: flex;
}

.carousel-image {
    margin: auto;
}

.carousel-info {
    width: 60%;
    height: 50vh;
    text-align: left;
}

.hero-banner {
    padding-top: 20em;
    background-size: cover;
    width: 100%;
    height: 95vh;
    justify-content: center;
    align-items: center;
}

.card-container-home {
    margin-top: 80px;
    margin-bottom: 60px;
}

.card-button {
    width: 100%;
    background-color: #00b786 !important;
    border: #00b786 !important;
}

.search-box {
    margin: auto;
    padding: 16px;
    background-color: white;
    border-radius: 24px;
}

.search-button {
    background-color: #00b786 !important;
    border: #00b786 !important;
    width: 100%;
    font-weight: 600 !important;
}

.slogan {
    font-weight: 700;
    display: block;
    color: white;
}

.home-container {
    text-align: center;
}