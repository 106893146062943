/* This is Desktop CSS Classes */
@media only screen and (min-width: 769px) {
}

/* This is Tablet and lower CSS Classes */
@media only screen and (max-width: 768px) {
}

/* This is mobile and lower CSS Classes */
@media only screen and (max-width: 425px) {
}

body {
  font-family: 'Montserrat', serif !important;
}

.top-margin {
  margin-top: 15px;
}

.break {
  flex-basis: 100%;
  height: 0;
}

