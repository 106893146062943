.about-us{
    text-align: left;
    margin: 40px;

    > h2 {
        text-align: center;
    }

    > p {
        margin-left: 20px;
    }
}