/* This is Desktop CSS Classes */
@media only screen and (min-width: 769px) {
    .card{
        width: 600px;
        margin-top: 30px;
    }
}

/* This is Tablet and lower CSS Classes */
@media only screen and (max-width: 768px) {
    .card{
        width: 400px;
        margin: 20px auto !important;
    }
}

/* This is mobile and lower CSS Classes */
@media only screen and (max-width: 425px) {
    .card{
        width: 200px;
        margin: 20px auto !important;
    }
}

.get-started-page{
    height: 77.8vh;
    display: flex
}

.get-started-cards{
    position: relative;
    margin: auto;

    .card-title {
        text-align: center;
    }
}

.card{
    margin: auto;
}

.card-text-bullets{
    text-align: left;
}
