/* This is Desktop CSS Classes */
@media only screen and (min-width: 769px) {
    .card-image {
        height: 300px !important;
        margin: 0px auto;
    }

    .carousel-info {
        margin-top: 12em;
        margin-left: 12em;
    }

    .carousel-image {
        width: 500px;
        height: 333px;
    }
}

/* This is Tablet and lower CSS Classes */
@media only screen and (max-width: 768px) {
}

/* This is mobile and lower CSS Classes */
@media only screen and (max-width: 425px) {
}

.vendor-sign-up{

    .card-container {
        margin-top:40px;
        margin-bottom: 40px;
        .card {
            margin-left: 80px;
            margin-right: 80px;
            border: none;
        }
    }
}

.vendor-sign-up-form{
    padding-top: 40px;
    padding-bottom: 40px;
    .card-title {
        text-align: center;
    }
    width: 90%;
}

.vendor-sign-up-banner {
    background-image: url("../Assets/hero-image-mobile.png");
}

.card{
    margin-left: auto;
}

.card-text-bullets{
    text-align: left;
}

.carousel-slide {
    display: flex;
}

.carousel-image {
    margin: auto;
}

.carousel-info {
    width: 60%;
    height: 50vh;
    text-align: left;
}

.card-image {
    height: 300px !important;
    margin: 0px auto;
}
