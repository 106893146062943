/* This is Desktop CSS Classes */
/* @media only screen and (min-width: 769px) {} */

/* This is Tablet and lower CSS Classes */
/* @media only screen and (max-width: 768px) {} */

/* This is mobile and lower CSS Classes */
/* @media only screen and (max-width: 425px) {} */

.brand-name {
    font-weight: 600;
    font-size: 34px !important;
}

.nav-link {
    font-size: 20px !important;
    font-weight: 600 !important;
}

#navbar {
    background-color: #00b786 !important;
}