/* This is Desktop CSS Classes */
/* @media only screen and (min-width: 769px) {} */

/* This is Tablet and lower CSS Classes */
/* @media only screen and (max-width: 768px) {} */

/* This is mobile and lower CSS Classes */
/* @media only screen and (max-width: 425px) {} */

.banner{
    background-image: url("../Assets/restaurant-banner.jpg");
    background-size: cover;
    width: 100%;
    height: 40vh;
}

.info{
    margin: 40px 0px 60px 40px;
    text-align: left;
}

.menu{
    width: 95%;
}

.menu-links{
    max-width: 20% !important;
    margin-left: 40px;
    text-align: left;
}

.menu-items{
    margin-right: 40px;
    margin-bottom: 60px;
}

.menu-items img{
    width: 150px;
    border-radius: 3% !important;
}

.menu-items .card{
    text-align: left;
    border-color: white;
}